
<template>
  <my-drawer ref="uploadDrawer" title="上传附件" :transfer="false">
    <template v-slot:main>
      <Upload
        class="upload-btn"
        :show-upload-list="false"
        :before-upload="handleBeforeUpload"
        action
      >
        <Icon type="ios-cloud-upload-outline" :size="30" />
      </Upload>
      <accessories-item
        v-if="accessories.file_name"
        :data-source="accessories"
      />
      <div style="display: flex; flex-direction: column; margin-top: 20px">
        <my-textarea
          ref="formDesc"
          title="备注名"
          :rows="10"
          v-model="accessories.more"
        />
      </div>
    </template>
    <template v-slot:footer>
      <Button size="large" type="primary" style="width: 100px" @click="submit"
        >保存</Button
      >
    </template>
  </my-drawer>
</template>

<script>
import MyTextArea from "../Form/MyTextArea";
import MyDrawer from "../MyDrawer";
import AccessoriesItem from "../Item/AccessoriesItem";
import * as oss from "../../utils/ossHelper";
import moment from "moment";
export default {
  components: {
    "accessories-item": AccessoriesItem,
    "my-drawer": MyDrawer,
    "my-textarea": MyTextArea,
  },
  data() {
    return {
      accessories: {
        file_name: null,
        file_url: null,
        size: 0,
        more: "",
        create_user_id: 0,
        create_user_name: "",
        create_user_img_url: null,
        create_time: "",
      },
    };
  },
  methods: {
    open(desc) {
      this.accessories = {
        file_name: null,
        file_url: null,
        size: 0,
        more: "",
        create_user_id: 0,
        create_user_name: "",
        create_user_img_url: null,
        create_time: "",
      };
      this.$refs.uploadDrawer.open();
    },
    submit() {
      if (!this.accessories.file_name) {
        return;
      }
      this.$emit("add", this.accessories);
      this.$refs.uploadDrawer.close();
    },
    /** 附件上传 */
    handleBeforeUpload(file) {
      this.$Spin.show();
      oss.upload(file, "pay-plan-accessories").then((result) => {
        this.accessories.file_name = result.name;
        this.accessories.file_url = result.fullPath;
        this.accessories.size = file.size;
        (this.accessories.create_user_id = localStorage.getItem("user_id")),
          (this.accessories.create_user_name = localStorage.getItem(
            "user_name"
          )),
          (this.accessories.create_user_img_url = localStorage.getItem(
            "img_url"
          )),
          (this.accessories.create_time = moment().format("YYYY/MM/DD HH:mm")),
          this.$Spin.hide();
      });
      return false;
    },
  },
};
</script>

<style scoped>
.upload-btn {
  margin: 20px 0px;
  cursor: pointer;
}
</style>